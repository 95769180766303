import { Center, SimpleGrid } from "@mantine/core";
import {
  IconAppWindow,
  IconBadgeVr,
  IconEyeExclamation,
  IconFileSpreadsheet,
  IconFlagExclamation,
  IconHeadset,
  IconRadar,
  IconTournament,
} from "@tabler/icons-react";
import { FeatureCard } from "../FeatureCard/FeatureCard";
import accDrive1 from "../../media/race_engineer.png";
import accDrive2 from "../../media/tyre_dmg.png";
import accDrive3 from "../../media/ylw_flags.png";
import accDrive4 from "../../media/community.png";
import accDrive5 from "../../media/radar.png";
import accDrive6 from "../../media/telemetry.png";
import accDrive7 from "../../media/spotting.png";
import accDrive8 from "../../media/vr.png";
import { useTranslation } from "react-i18next";
export const dynamicFeatureList = {
  title: "features.<id>.title",
  subtitle: "features.<id>.subtitle",
  description: "features.<id>.description",
  modal: "features.<id>.modalText",
  features: [
    {
      id: "feature1",
      icon: IconHeadset,
      image: accDrive1
    },
    {
      id: "feature2",
      icon: IconFileSpreadsheet,
      image: accDrive2
    },
    {
      id: "feature3",
      icon: IconEyeExclamation,
      image: accDrive3
    },
    {
      id: "feature4",
      icon: IconTournament,
      image: accDrive4
    },
    {
      id: "feature5",
      icon: IconRadar,
      image: accDrive5
    },
    {
      id: "feature6",
      icon: IconFlagExclamation,
      image: accDrive6
    },
    {
      id: "feature7",
      icon: IconAppWindow,
      image: accDrive7
    },
    {
      id: "feature8",
      icon: IconBadgeVr,
      image: accDrive8
    },
]
};
export function FeatureCardList() {
  const { t } = useTranslation("translation");
  const dynamicFeatureCards = dynamicFeatureList.features.map((feature, index) => {
    return <Center id={feature.id}
        key={index}>
        <FeatureCard
          featureName={t(dynamicFeatureList.title.replace("<id>", feature.id))}
          featureText={t(dynamicFeatureList.subtitle.replace("<id>", feature.id))}
          longText={t(dynamicFeatureList.description.replace("<id>", feature.id))}
          modalText={t(dynamicFeatureList.modal.replace("<id>", feature.id))}
          icon={feature.icon}
          image={feature.image}
        />
      </Center>
  });
  return (
    <Center>
      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 4 }}
        spacing="xs"
        verticalSpacing="xl"
        style={{ maxWidth: "92em", width: '100%' }}>
        {dynamicFeatureCards}
      </SimpleGrid>
    </Center>
  );
}
