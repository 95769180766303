import { notifications } from "@mantine/notifications";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { ImageHero } from "../ImageHero/ImageHero";

import { VideoHero } from "../VideoHero/VideoHero";
import { DEBUG } from "../..";

export function HeroTitle() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if(DEBUG){
      notifications.show({
        id: "websiteStatus",
        title: "This website is in beta",
        message: "Current version: 1.0.0. Last updated on 11.07.2024 at 13:17",
        color: "red",
        icon: <IconAlertTriangle size={"1rem"} />,
      });
    }
  }, []);

  return <div id="home">{isDesktop ? <VideoHero /> : <ImageHero />}</div>;
}
