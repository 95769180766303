import { UnstyledButton, Group, Avatar, Text, rem, Menu, Collapse, Center, Box } from "@mantine/core";
import {
  IconChevronDown,
  IconLogout,

  IconUser,
} from "@tabler/icons-react";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../auth/SessionContext";
import { useTranslation } from "react-i18next";
import classes from './UserDisplay.module.css';


export function UserDisplay() {
  const { sessionData, deleteSessionAsync } = useSession();
  const isLargeScreen = useMediaQuery('(min-width: 768px)');
  const [accLinksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const [, setUserMenuOpened] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const redirectToProfile = () => {
    navigate("/account");
  };
  const redirectToHome = () => {
    navigate("/");
  };

  useEffect(() => {
    // Check if 'userData' is stored in session storage
    //const storedUserDataString = sessionStorage.getItem('userData');

    //if (storedUserDataString) {
      // If found, parse and set the stored userData
    //  const storedUserData: UserData = JSON.parse(storedUserDataString);
    //  setUserData(storedUserData);
    //} else {
      // Handle the case where userData is not found in session storage
    //  console.log('User data not found in session storage.');
    //}
    //if(data.logged_in)
      //setUserData(data);
  }, []);

  const handleLogout = async () => {
    await deleteSessionAsync();
    redirectToHome();
  };

  const links = (
    <>
      <Menu.Item
        leftSection={
          <IconUser
            style={{ width: rem(16), height: rem(16) }}
            stroke={1.5}
          />
        }
        onClick={() => redirectToProfile()}
      >
        {t("menu.user.account")}
      </Menu.Item>
      <Menu.Item onClick={handleLogout}
        color="red"
        leftSection={
          <IconLogout
            style={{ width: rem(16), height: rem(16) }}
            stroke={1.5}
          />
        }>
        {t("menu.user.logout")}
      </Menu.Item>
    </>);
    
  const blinks = (
    <>
      <UnstyledButton onClick={() => redirectToProfile()} className={classes.accSubLink}>
        <Group wrap="nowrap" align="flex-start">
          <IconUser
            style={{ width: rem(16), height: rem(16) }}
            stroke={1.5}
          />
          <Text size="xl">{t("menu.user.account")}</Text>
        </Group>
      </UnstyledButton>
      <UnstyledButton onClick={() => handleLogout()} className={classes.accSubLink}>
        <Group wrap="nowrap" align="flex-start" style={{color: "var(--mantine-color-red-6)"}}>
          <IconLogout
            style={{ width: rem(16), height: rem(16) }}
            stroke={1.5}
          />
          <Text size="xl">{t("menu.user.logout")}</Text>
        </Group>
      </UnstyledButton>
    </>);

  return (
    <>
    {isLargeScreen ? (
      <Menu 
        width={170}
        position="bottom-end"
        transitionProps={{ transition: "pop-top-right" }}
        onClose={() => setUserMenuOpened(false)}
        onOpen={() => setUserMenuOpened(true)}
        withinPortal>
        <Menu.Target>
          <UnstyledButton style={{maxWidth: '150px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={toggleLinks}>
            <Group gap={7} style={{ flexGrow: 1 }}>
              <Avatar src={sessionData?.avatar} radius="xl" size={30} />
              <Text fw={500} className={classes.userName} size="sm" lh={1} mr={3}>
                {sessionData?.name}
              </Text>
            </Group>
            <IconChevronDown
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown style={{ zIndex: "1000000" }}>
          {links}
        </Menu.Dropdown>
      </Menu>
    ) : (
      <>
        <UnstyledButton className={classes.accLink} onClick={toggleLinks}>
          <Center inline>
            <Box component="span" mr={5}>
              <Group gap={7} style={{ flexGrow: 1 }}>
                <Avatar src={sessionData?.avatar} radius="xl" size={30} />
                <Text fw={500} className={classes.userName} size="xl" lh={1} mr={3} >
                  {sessionData?.name}
                </Text>
              <IconChevronDown
                style={{ width: rem(16), height: rem(16) }}
                color={"#66ae3f"}
              />
              </Group>
            </Box>
          </Center>
        </UnstyledButton>
        <Collapse hiddenFrom="sm" in={accLinksOpened}>{blinks}</Collapse>
      </>
    )}
    </>
  );
}
