import { Accordion, Center, Text, Container, useMantineTheme, CopyButton, Tooltip, ActionIcon, rem, Badge, Loader, Indicator } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './OrderList.module.css';
import { useSession } from "../../auth/SessionContext";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { format, parseISO } from 'date-fns';
import { DEBUG } from "../..";

interface OrderData {
  id: bigint;
  ident: string;
  owner: string;
  discordId: string;
  created: string;
  features: string;
  refunded: boolean;
  steamId: bigint;
  strSteamId: string;
  steamIdBindingTime: string;
  affiliate: string;
}
async function LoadOrderDataAsync(): Promise<OrderData[]>{
  return fetch('/dyn/get-orders.php', {
    method: 'GET',
    credentials: 'include' // Dies sendet die Cookies mit
  })
  .then(response => response.json())
  .catch(error => { if(DEBUG) console.error('Error:', error) });
}
function formatDate(dateString : string): string {
  try {
    const date = parseISO(dateString);
    return format(date, 'dd.MM.yyyy HH:mm');
  } catch {
    return dateString; // Return original string if parsing fails
  }
}
export function OrderList() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  };
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);  
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const { t } = useTranslation("translation");
  const { sessionData, loadSessionDataAsync, deleteSessionAsync, setSessionData } = useSession();
  const theme = useMantineTheme();
  useEffect(() => {
    const fetchData = async () => {
      try {
        var result = await loadSessionDataAsync();
        if(DEBUG) console.log("Imported sessionData in OrderList: ", result);
        if(!result) {
          handleRedirect();
        } else {
          const orderData = await LoadOrderDataAsync();
          if(DEBUG) console.log("Order Data: ", orderData);
          setOrderData(orderData);
          setLoading(false);
        }
      } catch (err) {
        if (err instanceof Error) {
          if(DEBUG) console.error('Error:', err.message);
          setError(err.message);
        } else {
          if(DEBUG) console.error('Unknown error:', err);
          setError('An unknown error occurred');
        }
        setLoading(false);
      }
    };
  fetchData();
  }, []);
  if (loading) {
    return (
      <Center style={{ height: '100vh' }}>
        <div>
          <Loader color="var(--mantine-color-green-filled)" size="xl" />
          <Text size="xl" mt="md">Loading...</Text>
        </div>
      </Center>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container size="100%" style={{ maxWidth: theme.breakpoints.lg, width: '100%' }}>
      <Accordion multiple variant="separated" defaultValue={['item-0']}>
      {orderData.map((data, index) => (
        <Accordion.Item value={`item-${index}`}>
          <Accordion.Control className={data.refunded ? classes.refunded : ''}><Indicator inline color="gray"  className={classes.badge} label={t("orders.header.creationDate")} position="middle-end" size={16}>{formatDate(data.created)}</Indicator></Accordion.Control>
          <Accordion.Panel className={data.refunded ? classes.refunded : ''}>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 0.9fr', gap: '8px', alignItems: 'baseline', alignContent: 'stretch', gridAutoRows: 'minmax(auto, max-content)' }}>
              <div style={{ display: 'flex', alignItems: 'stretch' }}><strong>{t("orders.header.owner")}:</strong></div>
              <div style={{ display: 'flex', alignItems: 'stretch' }}>{data.owner}</div>
              <div style={{ display: 'flex', alignItems: 'stretch' }}><strong>{t("orders.header.key")}:</strong></div>
              <div style={{ display: 'flex', alignItems: 'stretch' }}>{data.ident}
                <CopyButton value={data.ident} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                    <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                      {copied ? (
                        <IconCheck style={{ width: rem(16) }} />
                      ) : (
                        <IconCopy style={{ width: rem(16) }} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
              </div>
              <div style={{ display: 'flex', alignItems: 'stretch' }}><strong>{t("orders.header.activatedTo")}:</strong></div>
              <div style={{ display: 'flex', alignItems: 'stretch' }}>{data.strSteamId}</div>
              <div style={{ display: 'flex', alignItems: 'stretch' }}><strong>{t("orders.header.afiliate")}:</strong></div>
              <div style={{ display: 'flex', alignItems: 'stretch' }}>{data.affiliate}</div>
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
      </Accordion>
    </Container>
  );
}
