import { useEffect } from 'react';
import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";
import { Button, Modal, Switch, Text, Title } from '@mantine/core';
import { CookiesPreferences, useConsent } from './ConsentContext';
import { IconCookie } from '@tabler/icons-react';
import classes from './CookieModal.module.css';
import { useTranslation } from "react-i18next";
import { DEBUG } from '../..';

const CookieModal = () => {
  const { t } = useTranslation("translations");
  const { cookiesPreferences, setCookiesPreferences, setShowBanner, setModalIsOpen, showBanner, modalIsOpen, handleSavePreferences, setShouldSavePreferences, shouldSavePreferences } = useConsent();
  const setOptionalValuesToTrue = (preferences: CookiesPreferences) => {
    setCookiesPreferences({
      ...preferences,
      optional: Object.fromEntries(
        Object.entries(preferences.optional).map(([key, value]) => [
          key,
          { ...value, value: true },
        ])
      ),
    });
    setShouldSavePreferences(true);
  };

  const setOptionalValuesToFalse = (preferences: CookiesPreferences) => {
    setCookiesPreferences({
      ...preferences,
      optional: Object.fromEntries(
        Object.entries(preferences.optional).map(([key, value]) => [
          key,
          { ...value, value: false },
        ])
      ),
    });
    setShouldSavePreferences(true);
  };
  const handleAccept = () => {
    setOptionalValuesToTrue(cookiesPreferences);
  };

  const handleDecline = () => {
    setOptionalValuesToFalse(cookiesPreferences);
  };

  const handleReset = () => {
    resetCookieConsentValue("CookieConsent");
    setShowBanner(true);
  };
  const handleSettings = () => {
    setModalIsOpen(true);
  };

  const handleOptionalChange = (e : any) => {
    const { name, checked } = e.target;
    setCookiesPreferences(prevState => ({
      ...prevState,
      optional: {
        ...prevState.optional,
        [name]: {
          ...prevState.optional[name as keyof typeof prevState.optional],
          value: checked,
        },
      }
    }));
  };
  useEffect(() => {
    if(DEBUG) console.log("Enabled Banner", showBanner);
  }, [showBanner]);

  useEffect(() => {
    if (shouldSavePreferences) {
      handleSavePreferences();
      setShouldSavePreferences(false);
    }
  }, [shouldSavePreferences, handleSavePreferences]);
  return (
    <>
      {showBanner ? (
        <div>
          <CookieConsent
            location="bottom"
            onAccept={handleAccept}
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px", position: "absolute", right: "0px", bottom: "0px", borderRadius: "var(--button-radius, var(--mantine-radius-default))", pointerEvents: "none", height: "0px", width: "0px", opacity: "0", visibility: "hidden" }}
            declineButtonStyle={{ color: "#f8f8f8", fontSize: "13px", background: "#c12f2f" }}
            extraCookieOptions={{ secure: true }} 
            contentClasses={classes.consent}
            containerClasses={classes.consentGrid}
          >
            <div style={{ margin: "10px" }}>
            <Text size="sm">
              Wir verwenden Cookies, um Ihre Erfahrung auf unserer Website zu verbessern. Indem Sie auf "Akzeptieren" klicken, stimmen Sie der Verwendung aller Cookies zu. Weitere Informationen finden Sie in unserer <a href="/privacy" style={{ color: "#f8e71c" }}>Datenschutzerklärung</a>.
            </Text>
            </div>
            <div className={classes.buttonGrid} style={{ margin: "10px" }}>
              <div>
                <Button onClick={handleAccept} style={{ fontSize: "13px", background: "#5C9D39" }}>{t('cookie.accept')}</Button>
                <Button onClick={handleDecline} style={{ fontSize: "13px", background: "red" }}>{t('cookie.deny')}</Button>
              </div>
              <div>
              <Button style={{ background: "#242424" }} onClick={handleSettings}>{t('cookie.settings')}</Button>
              </div>
            </div>
          </CookieConsent>
          <Modal
            opened={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            zIndex={1001}
            title={<Title>Cookie-{t('cookie.settings')}</Title>}
          >
            <form>
              {Object.entries(cookiesPreferences.neccessary).map(([key, { value, name }]) => (
                  <Switch
                    defaultChecked
                    label={name}
                    disabled={true}
                    name={key}
                  />
              ))}
              <br />
              {Object.entries(cookiesPreferences.optional).map(([key, { value, name }]) => (
                  <Switch
                    checked={value}
                    label={name}
                    name={key}
                    onChange={handleOptionalChange}
                  />
              ))}
              <br />
              <Button onClick={handleSavePreferences} style={{ color: "#4e503b", fontSize: "13px", background: "rgb(255, 212, 45)" }}>{t('cookie.save')}</Button>
            </form>
          </Modal>
        </div>
      ) : (
        <Button onClick={() => handleReset()} style={{ position: 'fixed', bottom: 10, right: 10, height: "50px", width: "50px", zIndex: 10}}>
          <IconCookie style={{ height: "100%", width: "100%" }}/>
        </Button>
      )}
    </>
  );
};

export default CookieModal;