import { Button, Flex } from "@mantine/core";
import { OrderList } from "../OrderList/OrderList";
import { IconDownload, IconFileDownload } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import classes from "./ProfileContent.module.css";

export default function ProfileContent(): React.JSX.Element {
  const { t } = useTranslation("translation");
  return (
    <>
      <Flex
        className={classes.flexContainer}
        direction={{ base: 'column', sm: 'row' }}
        gap={{ base: 'sm', sm: 'lg' }}
        justify={{ sm: 'center' }}
      >
        <Button variant="gradient" component="a" href="https://release.accdrive.com/ACC%20DRIVE%20Setup.exe"
                gradient={{ from: "green", to: "#66ae3f" }}
                size="md" className={classes.control}>{t("general.downloadDrive")}<IconDownload /></Button>
        <Button variant="gradient" component="a" href="https://download.visualstudio.microsoft.com/download/pr/84ba33d4-4407-4572-9bfa-414d26e7c67c/bb81f8c9e6c9ee1ca547396f6e71b65f/windowsdesktop-runtime-8.0.2-win-x64.exe"
                gradient={{ from: "green", to: "#66ae3f" }}
                size="md" className={classes.control}>{t("general.downloadNet")}<IconDownload /></Button>
        <Button variant="gradient" component="a" href="https://docs.google.com/document/d/1fKVPODBSVCJG_CTJ8idaRlL_scvi0xRxGev0YIzzEPE/export?format=pdf"
                gradient={{ from: "green", to: "#66ae3f" }}
                size="md" className={classes.control}>{t("general.downloadManual")}<IconFileDownload /></Button>
      </Flex>
      {process.env.NODE_ENV === 'development' ? (
        <></>
      ) : (
        <OrderList />
      )}
    </>
  );
}
