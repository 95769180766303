import { useState } from "react";
import { UnstyledButton, Menu, Group } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import classes from "./LanguagePicker.module.css";
import ReactCountryFlag from "react-country-flag";
import { MyLanguages } from "../../i18n";
import { useLanguage } from '../../LanguageContext';



export function LanguagePicker() {
  const { selectedLanguage, changeLanguage } = useLanguage();
  const [opened, setOpened] = useState(false)
  ;
  const items = MyLanguages.map((language) => (
    <Menu.Item
      leftSection={<ReactCountryFlag countryCode={language.code} svg />}
      onClick={() => changeLanguage(language)}
      key={language.label}
    >
      {language.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="auto"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={classes.control}
          data-expanded={opened || undefined}
        >
          <Group gap="xs">
            <ReactCountryFlag
              countryCode={selectedLanguage.code}
              svg
              width={22}
              height={22}
            />
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  );
}
