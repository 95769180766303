// ConsentContext.js
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { Cookies } from "react-cookie-consent";

const ConsentContext = createContext<ConsentContextType | undefined>(undefined);

export type Preference = {
  value: boolean;
  name: string;
};

export type CookiesPreferences = {
  optional: Record<string, Preference>;
  neccessary: Record<string, Preference>;
};
export type ConsentContextType = {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showBanner: boolean;
  setShowBanner: React.Dispatch<React.SetStateAction<boolean>>;
  shouldSavePreferences: boolean;
  setShouldSavePreferences: React.Dispatch<React.SetStateAction<boolean>>;
  cookiesPreferences: CookiesPreferences;
  setCookiesPreferences: React.Dispatch<React.SetStateAction<CookiesPreferences>>;
  handleSavePreferences: () => void;
};
export type ConsentProviderProps = {
  children: ReactNode;
};

export const InitialCookiesPreferences: CookiesPreferences = {
  optional: {
    youtube: {
      value: false,
      name: "YouTube"
    }
  },
  neccessary: {
    paypal: {
      value: true,
      name: "PayPal "
    }
  }
};

export const useConsent = () => {
  const context = useContext(ConsentContext);
  if (!context) {
    throw new Error('useConsent must be used within a ConsentProvider');
  }
  return context;
};
export const ConsentProvider = ({ children }: ConsentProviderProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [shouldSavePreferences, setShouldSavePreferences] = useState(false);
  const [cookiesPreferences, setCookiesPreferences] = useState<CookiesPreferences>(InitialCookiesPreferences);
  const handleSavePreferences = () => {
    Cookies.set('CookieConsent', cookiesPreferences, { expires: 365 });
    // Initialisiere oder deaktiviere hier deine Cookies basierend auf den Präferenzen
    setModalIsOpen(false);
    setShowBanner(false);
  };

  useEffect(() => {
    const savedPreferences = Cookies.getJSON('CookieConsent');
    if (savedPreferences) {
      setCookiesPreferences(savedPreferences);
    }else{
      setShowBanner(true);
    }
  }, []);
  return (
    <ConsentContext.Provider value={{ modalIsOpen, setModalIsOpen, shouldSavePreferences, setShouldSavePreferences, showBanner, setShowBanner, cookiesPreferences, setCookiesPreferences, handleSavePreferences }}>
      {children}
    </ConsentContext.Provider>
  );
};