import { useEffect } from 'react';
import axios from 'axios';
import { DEBUG } from '.';
const readAny = true;
const CheckRefLink = () => {
    useEffect(() => {
        // Extrahiere den `ref`-Parameter aus der URL
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');

        if (ref) {
            if(readAny)
            // Sende eine Anfrage an das PHP-Skript zur Überprüfung des Reflinks
                localStorage.setItem('ref', ref);
            else
                axios.get(`/dyn/checkref.php?ref=${ref}`)
                    .then(response => {
                        if (response.status === 202) {
                            // Speichere den Reflink in Local Storage, wenn er gültig ist
                            localStorage.setItem('ref', ref);
                            if(DEBUG) console.log('Reflink valid und gespeichert:', ref);
                        } else if (response.status === 406) {
                            if(DEBUG) console.log('Reflink invalid');
                        }
                    })
                    .catch(error => {
                        if(DEBUG) console.error('Fehler bei der Reflink-Überprüfung:', error);
                    });
        }
    }, []);

    return null;
};

export default CheckRefLink;