import {
  Container,
  Title,
  Text,
  Space,
  Center,
  SimpleGrid,
  Button,
} from "@mantine/core";
import classes from "./CTAVideoBanner.module.css";
import { Trans } from "react-i18next";
import YouTube, { YouTubeProps } from "react-youtube";
import { useConsent } from "../CookieBanner/ConsentContext";
import { useEffect } from "react";
import { DEBUG } from "../..";

export function CTAVideoBanner() {
  const { cookiesPreferences, setCookiesPreferences, handleSavePreferences, shouldSavePreferences, setShouldSavePreferences } = useConsent();
  const opts: YouTubeProps["opts"] = {
    height: window.innerWidth >= 600 ? "360" : "360",
    width: window.innerWidth >= 600 ? "640" : "100%",
    host: 'https://www.youtube-nocookie.com',
    playerVars: {
      // Aktiviert den Datenschutzmodus von YouTube
      rel: 0, // Verhindert das Anzeigen verwandter Videos am Ende
    },
  };

  const handleYoutube = () => {
    const pref = cookiesPreferences;
    pref.optional.youtube.value = true;
    setCookiesPreferences(pref);
    setShouldSavePreferences(true);
  };
  

  useEffect(() => {
    if (shouldSavePreferences) {
      handleSavePreferences();
      setShouldSavePreferences(false);
      if(DEBUG) console.log(cookiesPreferences);
    }
  }, [shouldSavePreferences, handleSavePreferences]);
  return (
    <Center>
      <SimpleGrid cols={{ base: 1, xs: 2 }} spacing={50} mt={30}>
        <Container className={classes.wrapper}>
          <Title className={classes.title}>
            <Text
              fs={"italic"}
              fw={900}
              inherit
              variant="gradient"
              gradient={{ from: "green", to: "#85d46c" }}
            >
              <Trans i18nKey="cta.video.title" />
            </Text>
          </Title>
          <Text size="xl" fw={500}>
            <Trans i18nKey="cta.video.subtitle" />
          </Text>
        </Container>
        <Center>
          {cookiesPreferences.optional.youtube.value ? (
            <YouTube
              className="youtubePlayer"
              opts={opts}
              videoId={"qMaQL1B0vaQ"}
            />
          ) : (
            <div><Text size="lg">
              <Trans i18nKey="cookie.YouTube.topRow" />
              <br />
              <Trans i18nKey="cookie.YouTube.bottomRow" />
              </Text><Button onClick={handleYoutube}><Trans i18nKey="cookie.YouTube.activate" /></Button></div>
          )}
        </Center>
      </SimpleGrid>

      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
    </Center>
  );
}
