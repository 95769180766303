import { createContext, useContext, useState, useEffect } from 'react';
import i18n, { GetInitialLanguage } from './i18n';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(GetInitialLanguage());

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.code);
  }, [selectedLanguage]);

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <LanguageContext.Provider value={{ selectedLanguage, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);