import classes from "./LoginButton.module.css";
import { ActionIcon, Center, Container, UnstyledButton } from "@mantine/core";
import { IconBrandSteam } from "@tabler/icons-react";
import { UserDisplay } from "../UserDisplay/UserDisplay";
import { useSession } from "../../auth/SessionContext";
import { DEBUG } from "../..";

export default function LoginButton(): React.JSX.Element {
  const { sessionData } = useSession();

  const handleSteamLogin = async () => {
    try {
      // Redirect the user to the Steam authentication page on your server
      window.location.href = "dyn/auth/init-openId.php";
    } catch (error) {
      if(DEBUG) console.error("Error initiating Steam login:", error);
    }
  };

  return (
    <>
      {sessionData.logged_in ? (
        <UserDisplay />
      ) : (
        <Center>
          <UnstyledButton className={classes.steambutton} onClick={handleSteamLogin}>
          <span>Login</span>
          <Container className={classes.icon}>
            <Center>
              <ActionIcon
                size={42}
                variant="transparent"
                color="rgba(255, 255, 255, 1)">
                <IconBrandSteam />
              </ActionIcon>
            </Center>
          </Container>
        </UnstyledButton>
        </Center>
      )}
    </>
  );
}
